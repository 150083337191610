import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/components/layout";
import { sStorageGet } from "@/utils/storage";

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/home",
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "home",
        name: "Home",
        title: "首页",
        component: () => import("@/views/home"),
      },
    ],
  },
  {
    path: "/merchantManagement",
    name: "MerchantManagement",
    component: Layout,
    redirect: "/merchantManagement/setIn",
    children: [
      {
        path: "setIn",
        name: "setIn",
        component: () => import("../views/merchantManagement"),
      },
      {
        path: "role",
        name: "RoleManagement",
        component: () => import("../views/merchantManagement/roles"),
      },
    ],
  },
  {
    path: "/memberManagement",
    name: "MemberManagement",
    component: Layout,
    redirect: "/memberManagement/member",
    children: [
      {
        path: "member",
        name: "Member",
        component: () => import("../views/memberManagement/member"),
      },
      {
        path: "consumptionRecord",
        name: "ConsumptionRecord",
        component: () => import("../views/memberManagement/consumptionRecord"),
      },
      {
        path: "integral",
        name: "Integral",
        component: () => import("../views/memberManagement/integral"),
      },
      {
        path: "thirdPartyLogin",
        name: "ThirdPartyLogin",
        component: () => import("../views/memberManagement/thirdPartyLogin"),
      },
    ],
  },
  {
    path: "/mobileManagement",
    name: "MobileManagement",
    component: Layout,
    redirect: "/mobileManagement/appConfig",
    children: [
      {
        path: "appConfig",
        name: "AppConfig",
        component: () => import("../views/mobileManagement/appConfig"),
      },
      {
        path: "officalAccountMenu",
        name: "OfficalAccountMenu",
        component: () => import("../views/mobileManagement/officalAccountMenu"),
      },
      {
        path: "templateMessage",
        name: "TemplateMessage",
        component: () => import("../views/mobileManagement/templateMessage"),
      },
      {
        path: "autoReply",
        name: "AutoReply",
        component: () => import("../views/mobileManagement/autoReply"),
      },
      {
        path: "wxMember",
        name: "WXMember",
        component: () => import("../views/mobileManagement/wxMember"),
      },
    ],
  },
  {
    path: "/bigData",
    name: "BigData",
    component: Layout,
    redirect: "/bigData/dataDisplay",
    children: [
      {
        path: "dataDisplay",
        name: "DataDisplay",
        component: () => import("../views/bigData"),
      },
    ],
  },
  {
    path: "/dataShare",
    name: "DataShare",
    component: Layout,
    redirect: "/dataShare/dictionary",
    children: [
      {
        path: "dictionary",
        name: "Dictionary",
        component: () => import("../views/dataShare/dictionary"),
      },
      {
        path: "manufacturer",
        name: "Manufacturer",
        component: () => import("../views/dataShare/manufacturer"),
      },
      {
        path: "projectClassification",
        name: "ProjectClassification",
        component: () => import("../views/dataShare/projectClassification"),
      },
      {
        path: "paymentMethod",
        name: "PaymentMethod",
        component: () => import("../views/dataShare/paymentMethod"),
      },
      {
        path: "caseDictionary",
        name: "CaseDictionary",
        component: () => import("../views/dataShare/caseDictionary"),
      },
      {
        path: "acupointDictionary",
        name: "AcupointDictionary",
        component: () => import("../views/dataShare/acupointDictionary"),
      },
    ],
  },

  {
    path: "/systemSetting",
    name: "SystemSetting",
    component: Layout,
    redirect: "/systemSetting/settings",
    children: [
      {
        path: "settings",
        name: "SystemSetting",
        component: () => import("../views/systemSetting/index"),
      },
      {
        path: "photo",
        name: "SystemPhoto",
        component: () => import("../views/systemSetting/photo"),
      },
    ],
  },
  {
    path: "/medicalDiagnosis",
    name: "MedicalDiagnosis",
    component: Layout,
    redirect: "/medicalDiagnosis/medical",
    children: [
      {
        path: "medical",
        name: "Medical",
        component: () => import("../views/medicalDiagnosis/medical"),
      },
      {
        path: "diagnosis",
        name: "Diagnosis",
        component: () => import("../views/medicalDiagnosis/diagnosis"),
      },
      {
        path: "medicalInsurance",
        name: "MedicalInsurance",
        component: () =>
          import("../views/medicalDiagnosis/diagnosis/medicalinsurance.vue"),
      },
    ],
  },
  {
    path: "/menuManagement",
    name: "MenuManagement",
    component: Layout,
    redirect: "/menuManagement/endMenu",
    children: [
      {
        path: "endMenu",
        name: "EndMenu",
        component: () => import("../views/menuManagement/endMenu"),
      },
      {
        path: "merchantMenu",
        name: "MerchantMenu",
        component: () => import("../views/menuManagement/merchantMenu"),
      },
    ],
  },
  {
    path: "/permissionManagement",
    name: "PermissionManagement",
    component: Layout,
    redirect: "/permissionManagement/userManagement",
    children: [
      {
        path: "userManagement",
        name: "UserManagement",
        component: () => import("../views/permissionManagement/userManagement"),
      },
    ],
  },
  {
    path: "/taskManagement",
    name: "TaskManagement",
    component: Layout,
    redirect: "/taskManagement/schedule",
    children: [
      {
        path: "schedule",
        name: "Schedule",
        component: () => import("../views/taskManagement/schedule"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    // hidden: true,
    component: () => import("@/views/login"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
    return;
  }
  const token = sStorageGet("ADMIN_ACCESS_TOKEN");
  if (token) {
    if (to.path === "/login") {
      next();
    } else {
      next();
    }
  } else {
    next({ path: "/login" });
  }
});

export default router;
