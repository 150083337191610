<template>
  <section class="body">
    <el-container>
      <el-aside width="64px" class="menu" v-show="menuShow1">
        <fake-menu></fake-menu>
      </el-aside>
      <el-aside width="200px" v-show="menuShow2">
        <side-menu></side-menu>
      </el-aside>

      <el-container>
        <el-header class="header">
          <c-header
            :menuShow1="menuShow1"
            :menuShow2="menuShow2"
            @switchMenu1="switchMenu1"
            @switchMenu2="switchMenu2"
          ></c-header>
        </el-header>
        <tag-header></tag-header>
        <el-main
          class="view"
          :style="{
            padding: $route.name === 'Home' ? 0 : '5px',
            border: '1px solid #e8eaec',
            height: 'calc(100vh - 85px)',
            boxSizing: 'border-box',
            overflow: 'auto',
            background: 'rgb(249,250,252)',
          }"
        >
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </section>
</template>

<script>
import SideMenu from './SideBar';
import FakeMenu from './FakeMenu';
import CHeader from './HeaderBar';
import TagHeader from './TagHeader';
export default {
  name: 'Layout',
  components: {
    FakeMenu,
    SideMenu,
    CHeader,
    TagHeader,
  },
  data() {
    return {
      menuShow1: true,
      menuShow2: true,
    };
  },
  methods: {
    switchMenu1(status) {
      this.menuShow1 = status;
    },
    switchMenu2(status) {
      this.menuShow2 = status;
    },
  },
};
</script>

<style>
.body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.body .header {
  height: 40px;
  border-left: 1px solid #e8eaec;
  border-bottom: 1px solid #e8eaec;
}

.body .context {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.body .context .menu {
  width: 250px;
  height: 100%;
  background-color: aquamarine;
}

.body .context .view {
  width: calc(100% - 250px);
}
</style>