<template>
  <div class="tag-container">
    <div class="arrow-wrap" @click="handleLeft">
      <el-icon><ArrowLeftBold /></el-icon>
    </div>
    <scroll-pane ref="scrllPane">
      <div class="tags-wrap">
        <div
          @click="toCurrentMenu(tag)"
          class="tag-item"
          v-for="tag in tagList"
          :key="tag.webUrl"
          :style="{ color: activeMenu === tag.webUrl ? ' #409eff' : '' }"
        >
          <div
            class="dot"
            :style="{
              'background-color': activeMenu === tag.webUrl ? ' #409eff' : '',
            }"
          ></div>
          {{ tag.menuName }}
          <el-icon
            v-show="tag.closable"
            style="margin-left: 10px"
            @click.stop="closeSelectedTag(tag)"
            ><Close
          /></el-icon>
        </div>
      </div>
    </scroll-pane>
    <div class="arrow-wrap" @click="handleRight">
      <el-icon><ArrowRightBold /></el-icon>
    </div>

    <el-dropdown>
      <div
        class="arrow-wrap"
        @click="handleClosePage"
        style="margin-left: 1px; width: 40px"
      >
        <el-icon><CircleCloseFilled /></el-icon>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="closeAll">全部关闭</el-dropdown-item>
          <el-dropdown-item @click="closeOther">关闭其它</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import ScrollPane from './scrollPane.vue';
export default {
  components: {
    ScrollPane,
  },
  data() {
    return {
      activeMenu: '/home',
      tagList: [
        {
          menuName: '首页',
          webUrl: '/home',
          closable: false,
        },
      ],
      menuNameList: [],
    };
  },
  created() {
    this.handleMenuName();
  },
  watch: {
    '$route.path'(newVal, oldVal) {
      let isOpenMenu = this.tagList.filter(
        (item) => item.webUrl === newVal
      ).length;
      this.activeMenu = newVal;
      if (isOpenMenu) {
        return;
      }
      if (this.menuNameList.length === 0) {
        this.handleMenuName();
      }

      this.tagList.push({
        menuName: this.menuNameList.filter((item) => item.webUrl === newVal)[0]
          ?.menuName,
        webUrl: newVal,
        closable: true,
      });
    },
  },
  methods: {
    // 处理页面对应的名称
    handleMenuName() {
      let oriMenuList = localStorage.getItem('666OKMenusList')
        ? JSON.parse(localStorage.getItem('666OKMenusList'))
        : [];
      this.menuNameList = this.flatArray(oriMenuList);
      if (this.$route.path !== '/home') {
        this.activeMenu = this.$route.path;
        this.tagList.push({
          menuName: this.menuNameList.filter(
            (item) => item.webUrl === this.$route.path
          )[0]?.menuName,
          webUrl: this.$route.path,
          closable: true,
        });
      }
    },

    flatArray(arr) {
      let subArr = [];
      arr.forEach((item) => {
        if (item.list && item.list.length > 0) {
          subArr = subArr.concat(this.flatArray(item.list));
        } else {
          subArr.push({
            webUrl: item.webUrl,
            menuName: item.menuName,
          });
        }
      });
      return subArr;
    },

    handleLeft() {
      let gapLeft = this.$refs.scrllPane.left || 0;
      if (gapLeft) {
        if (gapLeft < -100) {
          gapLeft = gapLeft + 100;
        } else {
          gapLeft = 0;
        }
        this.$refs.scrllPane.left = gapLeft;
      }
    },
    handleRight() {
      this.$refs.scrllPane.left = this.$refs.scrllPane.left - 100;
    },
    toCurrentMenu(menu) {
      this.$router.push({
        path: menu.webUrl,
      });
    },
    closeAll() {
      this.tagList = [
        {
          menuName: '首页',
          webUrl: '/home',
          closable: false,
        },
      ];
      this.$router.push({
        path: '/home',
      });
    },
    closeOther() {
      const currentWebUrl = this.activeMenu;
      const currentMenu = this.tagList.filter(
        (item) => item.webUrl === currentWebUrl
      );
      this.tagList = [
        {
          menuName: '首页',
          webUrl: '/home',
          closable: false,
        },
        ...currentMenu,
      ];
    },
    closeSelectedTag(menu) {
      this.tagList.map((item, index) => {
        if (menu.webUrl === item.webUrl) {
          if (this.activeMenu === menu.webUrl) {
            this.$router.push({
              path: '/home',
            });
          }
          this.tagList.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
.tag-container {
  padding: 1px;
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
  display: flex;
  color: #606266;
  font-size: 14px;
  border-bottom: 1px solid #e8eaec;
}

.arrow-wrap {
  width: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow-wrap :hover {
  color: #409eff;
}

.tags-wrap {
  padding: 3px;
  display: flex;
  align-items: center;
  overflow: auto;
}

.tag-item {
  background-color: #fff;
  cursor: pointer;
  padding: 7px 10px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.dot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 8px;
}

.close-wrap .close-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
}
</style>
