<template>
  <div class="header-container">
    <div class="left">
      <el-icon v-show="menuShow1 || menuShow2" class="fold" @click="foldMenu()"
        ><Fold
      /></el-icon>
      <el-icon
        v-show="!menuShow1 && !menuShow2"
        class="fold"
        @click="unFoldMenu()"
        ><Expand
      /></el-icon>
      <div class="icon-wrap">
        <el-icon><Menu /></el-icon>
        <span v-show="isDesktop">快捷键</span>
      </div>
    </div>
    <!-- <div v-show="isDesktop">
      <span class="mr15">单位：{{ userInfo.hosNname }}</span>
      <span class="mr15">定点机构：{{ userInfo.hosCode }}</span>
      <span class="mr15">科室：{{ userInfo.hosCode }}</span>
      <span>操作员：<span class="green">赵艳铃</span></span>
    </div> -->
    <div class="right">
      <div class="mr15 icon-wrap">
        <el-icon><SwitchButton /></el-icon><span v-show="isDesktop">锁屏</span>
      </div>
      <div class="mr15 icon-wrap">
        <el-icon><TrendCharts /></el-icon><span v-show="isDesktop">流程图</span>
      </div>
      <div class="mr15 icon-wrap">
        <el-icon><Opportunity /></el-icon><span v-show="isDesktop">消息</span>
      </div>
      <div class="mr15 icon-wrap">
        <el-icon><QuestionFilled /></el-icon
        ><span v-show="isDesktop">帮助</span>
      </div>
      <div class="mr15 icon-wrap">
        <el-icon><VideoCamera /></el-icon><span v-show="isDesktop">教程</span>
      </div>
      <div class="mr15 icon-wrap" v-if="!isfullscreen" @click="toFullScreen()">
        <el-icon><FullScreen /></el-icon>
      </div>
      <div class="mr15 icon-wrap" v-else @click="toExitFullScreen()">
        <el-icon><SemiSelect /></el-icon>
      </div>
      <div class="icon-wrap">
        <img src="../../../../src/assets/images/picture.webp" class="avator" />

        <el-dropdown>
          <div
            class="peopleCenter"
            @click="handlePeopleCenter"
            style="margin-left: 1px; width: 40px"
          >
            <el-icon><CaretBottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="personalMsg">个人信息</el-dropdown-item>
              <el-dropdown-item @click="closeLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceEnquire, DEVICE_TYPE } from '@/utils/device';
import { lStorageClear, sStorageClear } from '@/utils/storage';
export default {
  props: {
    menuShow1: {
      type: Boolean,
    },
    menuShow2: {
      type: Boolean,
    },
  },
  data() {
    return {
      userInfo: {
        hosNname: '智慧云医养',
        hosCode: '111111',
        operName: '赵艳铃',
      },
      // 是否全屏
      isfullscreen: false,
      //
      isDesktop: true,
    };
  },
  mounted() {
    deviceEnquire((deviceType) => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          this.isDesktop = true;
          break;
        case DEVICE_TYPE.TABLET:
          this.isDesktop = false;
          break;
        case DEVICE_TYPE.MOBILE:
        default:
          this.isDesktop = false;
          break;
      }
    });
  },
  methods: {
    // 菜单折叠
    foldMenu() {
      if (this.menuShow2) {
        this.$emit('switchMenu2', false);
      } else if (this.menuShow1) {
        this.$emit('switchMenu1', false);
      }
    },
    unFoldMenu() {
      this.$emit('switchMenu1', true);
      this.$emit('switchMenu2', true);
    },
    // 全屏
    toFullScreen() {
      let fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled;
      if (!fullscreenEnabled) {
        this.$notification.warning({
          message: '温馨提示',
          description: '该浏览器暂不支持全屏',
        });
        return;
      }
      this.isfullscreen = true;
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    },
    // 关闭全屏
    toExitFullScreen() {
      this.isfullscreen = false;
      if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    },
    closeLogin() {
      sStorageClear();
      lStorageClear();
      this.$router.push({ path: '/login' });
    },
    personalMsg() {},
  },
};
</script>

<style scoped>
.header-container {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #606266;
  font-size: 14px;
}

.mr15 {
  margin-right: 15px;
}

.green {
  color: green;
}

.left {
  display: flex;
  align-items: center;
}

.left .fold {
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.right {
  display: flex;
}

.icon-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>