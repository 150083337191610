import { createApp } from "vue";
import App from "./App.vue";

import router from "@/router";

import ElementUI from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

// 导入全局样式表
import "./assets/css/global.css";
//导入字体图标
import "./assets/fonts1/iconfont.css";
// import './assets/fonts/iconfont.css'

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router);
app.use(ElementUI, {
  locale: zhCn,
});
app.mount("#app");
