<template>
  <div>
    <div class="logo-wrap">
      <img src="../../../assets/images/logo.png" style="height: 50px" />
    </div>
    <el-menu
      style="border-right: none"
      router
      :default-active="$route.path"
      class="menus"
      unique-opened
    >
      <template v-for="item in menuList">
        <template v-if="!item.list?.length">
          <el-menu-item :index="item.webUrl" :key="item.id">
            <img v-if="item.menuIcon" :src="item.menuIcon" />
            <span>{{ item.menuName }}</span>
          </el-menu-item>
        </template>
        <template v-else>
          <h-sub-menu :key="item.id" :menu-info="item" />
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { lStorageGet, lStorageSet } from '@/utils/storage';
import { getMenuListApi } from '@/api/menu';
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus';
const SubMenu = defineComponent({
  name: 'HSubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  template: `
            <el-sub-menu :index="menuInfo.id + ''" :key="menuInfo.id">
                <template #title>
                    <img v-if="menuInfo.menuIcon" :src="menuInfo.menuIcon" />
                    <span>{{menuInfo.menuName}}</span>
                </template>
                <template v-for="m in menuInfo.list" >
                    <template v-if="!m.list?.length">
                        <el-menu-item :index="m.webUrl" :key="m.id">
                            <img v-if="m.menuIcon"  :src="m.menuIcon" />
                            <span>{{m.menuName}}</span>
                        </el-menu-item>
                    </template>
                    <template v-else>
                        <h-sub-menu
                          :key="m.id"
                          :menu-info="m"
                        />
                    </template>
                </template>
                
            </el-sub-menu>
        `,
});
export default {
  components: {
    'h-sub-menu': SubMenu,
  },
  data() {
    return {
      menuList: [],
    };
  },
  created() {
    this.getMenuList();
  },
  methods: {
    getMenuList() {
      let menuList = lStorageGet('666OKMenusList') || [];
      this.menuList = menuList;
      if (menuList.length === 0) {
        getMenuListApi().then((res) => {
          if (res.code === 200) {
            lStorageSet('666OKMenusList', res.data);
            this.getMenuList();
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    },
    formatterMenu(arr, concatArr) {
      arr.map((item) => {
        if (item.children && item.children.length > 0) {
          this.formatterMenu(item.children, concatArr);
        } else {
          concatArr.push(item);
        }
      });
      return concatArr;
    },
  },
};
</script>

<style scoped>
.logo-wrap {
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e8eaec;
}
.menus {
  height: calc(100vh - 120px);
  overflow: auto;
}
</style>

<style lang="less">
.el-menu-item,
.el-sub-menu__title {
  padding-left: 5px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 2px;
  }
}
</style>