export function sStorageSet(name, data) {
  window.sessionStorage.setItem(`${name}`, JSON.stringify(data));
}

export function sStorageGet(name) {
  const data = window.sessionStorage.getItem(`${name}`);
  try {
    return JSON.parse(data);
  } catch {
    return false;
  }
}

export function sStorageClear() {
  window.sessionStorage.clear();
}

export function lStorageSet(name, data) {
  window.localStorage.setItem(`${name}`, JSON.stringify(data));
}

export function lStorageGet(name) {
  const data = window.localStorage.getItem(`${name}`);
  try {
    return JSON.parse(data);
  } catch {
    return false;
  }
}

export function lStorageClear() {
  window.localStorage.clear();
}
