<template>
  <div class="top-menu">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
    >
      <div>
        <img class="picture" src="@/assets/images/picture.webp" alt="" />
      </div>
      <el-menu-item index="1">
        <el-icon class="iconfont icon-fangdajing"></el-icon>
        <template #title>搜索</template>
      </el-menu-item>
      <el-menu-item index="2">
        <el-icon class="iconfont icon-shouyefill1"></el-icon>
        <template #title>主页</template>
      </el-menu-item>
      <el-menu-item index="3">
        <el-icon class="iconfont icon-envelope-full"></el-icon>
        <template #title>通知公告</template>
      </el-menu-item>
      <el-menu-item index="4">
        <el-icon class="iconfont icon-xinxifill"></el-icon>
        <template #title>客服</template>
      </el-menu-item>
      <el-menu-item index="5">
        <el-icon class="iconfont icon-wenjian1"></el-icon>
        <template #title>文件管理</template>
      </el-menu-item>
      <el-menu-item index="6">
        <div><img src="@/assets/images/yibao.png" alt="" class="yibao" /></div>
        <!-- <el-icon class="iconfont icon-wenjian1"></el-icon> -->
        <template #title>国家医保</template>
      </el-menu-item>
    </el-menu>
  </div>

  <div class="bottom-menu">
    <el-menu :collapse="isCollapse">
      <el-menu-item index="6">
        <el-icon class="iconfont icon-lingdang"></el-icon>
        <template #title>Navigator Four</template>
      </el-menu-item>
      <el-menu-item index="7">
        <el-icon class="iconfont icon-xuanxiang1"></el-icon>
        <template #title>Navigator Four</template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const isCollapse = ref(true);
</script>
<style>
.top-menu {
  height: calc(100% - 86px);
}

.bottom-menu {
  position: absolute;
  bottom: 0;
}

.picture {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 10px 10px 0;
}

.yibao {
  height: 29px;
  width: 29px;
  margin: -2px -2px 0;
}
</style>