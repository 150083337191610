import request from "@/utils/request";

// 获取菜单列表--总后台
export function getMenuListApi() {
  return request({
    url: "/menu/index",
    method: "get",
  });
}

// 创建菜单--总后台
export function createMenuApi(data) {
  return request({
    url: "/menu/create",
    method: "post",
    data,
  });
}

// 修改菜单--总后台
export function updateMenuApi(data) {
  return request({
    url: "/menu/update",
    method: "post",
    data,
  });
}

// 获取菜单列表--商家
export function getMerchantMenuListApi() {
  return request({
    url: "/merchant/menu/index",
    method: "get",
  });
}

// 创建菜单--商家
export function createMerchantMenuApi(data) {
  return request({
    url: "/merchant/menu/create",
    method: "post",
    data,
  });
}

// 修改菜单--商家
export function updateMerchantMenuApi(data) {
  return request({
    url: "/merchant/menu/update",
    method: "post",
    data,
  });
}
