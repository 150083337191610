import axios from "axios";
import { ElMessage, ElNotification } from "element-plus";
import router from "@/router";
import { sStorageGet } from "./storage";

const request = axios.create({
  baseURL: "/hospital-admin",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      if (sStorageGet("ADMIN_ACCESS_TOKEN")) {
        headers["Authorization"] = sStorageGet("ADMIN_ACCESS_TOKEN");
      }
      if (headers["Content-Type"] === "multipart/form-data") {
        return data;
      } else {
        return JSON.stringify(data);
      }
    },
  ],
});

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.data.code === 10001) {
      ElNotification({
        title: "温馨提示",
        message: "用户信息过期，请重新登录",
        type: "error",
      });
      setTimeout(() => {
        router.push("/login");
      }, 3000);
    }
    return response.data;
  },
  (error) => {
    if (error.response) {
      if ([502, 500].includes(error.response.status)) {
        ElMessage.error("服务异常，请联系管理员");
      }
    }
    return Promise.reject(error);
  }
);

export default request;
